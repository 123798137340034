<template>
  <div class="wrap">
    <div id="three" ref="thr">
    </div>
    <color-picker v-model="color" @change="change"></color-picker>
    <div class="data">
      <p class="header">
        <span>产品名称</span>
        <span>产品编号</span>
        <span>净重(g)</span>
        <span>螺丝类别</span>
        <span>螺丝数量</span>
      </p>
      <p class="num">
          <input-dom v-for="(item, index) in data" :key='index' :value="item.msg" :num="index"></input-dom>
      </p>
    </div>
    <div class="infomation" ref="infomation">
      <p>
        <span class="left">材料</span>
        <span class="right">{{info.material}}</span>
      </p>
      <p>
        <span class="left">重量</span>
        <span class="right">{{info.weight}}</span>
      </p>
      <p>
        <span class="left">颜色编号</span>
        <span class="right" v-bind:style="changeColor(info.color)">{{info.color}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'

import InputDom from './InputDom.vue';

let scene, renderer, camera, loader, controls, raycaster, dragcontrols;
let mouse, selected_obj, groupObj;
let infomation = {
  'pj':{
    material: '橡胶',
    color: '#ffffff',
    weight: '50g',
  },
  'pj7':{
    material: '金属',
    color: '#ffffff',
    weight: '150g',
  },
  'zc':{
    material: '金属',
    color: '#ffffff',
    weight: '110g',
  },
  'top':{
    material: '塑料',
    color: '#f2870c',
    weight: '110g',
  },
  'zz-1':{
    material: '橡胶',
    color: '#13ed9a',
    weight: '80g',
  },
  'zz-2':{
    material: '橡胶',
    color: '#4613ed',
    weight: '80g',
  },
  'zz-3':{
    material: '橡胶',
    color: '#ed1313',
    weight: '80g',
  },
  'ty':{
    material: '塑料',
    color: '#d0ed13',
    weight: '30g',
  },
};
export default {
  name: "three",
  mounted() {
    this.initThree()
  },
  data: function() {
    return {
      objs: [],
      color: '',
      info: {
        material: '金属',
        color: '#ffffff',
        weight: '100g',
      },
      data: [
        {msg:'陀螺'},
        {msg:'329'},
        {msg:'500'},
        {msg:'M2'},
        {msg:'6'}
      ]
    };
  },
  components: {
    InputDom
  },
  methods: {
    // 初始化一个场景
    initScene() {
      scene = new THREE.Scene();
			scene.background = new THREE.Color( 0x000000 );
			// scene.fog = new THREE.Fog(scene.background, 1, 100);
    },
    // 初始化一个摄像机
    initCamera() {
      camera = new THREE.PerspectiveCamera( 90, this.$refs.thr.clientWidth / this.$refs.thr.clientHeight, 1, 2500 );
      // camera.position.z = 500;
      // camera.position.y = 1000;
      camera.position.x = -1000;
    },
    // 初始化一个光源
    initLight() {
      let directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 100, 300);
      let dhelper = new THREE.DirectionalLightHelper(directionalLight, 5, 'red'); 
      scene.add(directionalLight);
      scene.add(dhelper);

      let directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight1.position.set(0, 100, -300);
      let dhelper1 = new THREE.DirectionalLightHelper(directionalLight1, 5, 'red'); 
      scene.add(directionalLight1);
      scene.add(dhelper1);
    },
    // 初始化一个渲染器
    initRenderer(dom) {
      renderer = new THREE.WebGLRenderer( {
        alpha: true,
        antialias: true
      });
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( this.$refs.thr.clientWidth, this.$refs.thr.clientHeight );
      dom.appendChild(renderer.domElement);
    },
    initDragControl() {
      // 模型加载成功后，添加拖拽控制
      dragcontrols = new DragControls( [ ... this.objs ], camera, renderer.domElement );
      dragcontrols.addEventListener( 'drag', () => {
        // 设置不能转动镜头
        controls.enableRotate = false;
        this.render();
      });

      dragcontrols.addEventListener( 'dragend', () => {
          controls.enableRotate = true;

          this.render();
      });
    },
    loadGLTF(files, objs) {
      if (!loader) {
        loader = new GLTFLoader();
      }
      
      let pos_z = 0;
      objs['all'] = new Array();
      Object.keys(files).forEach(group_name => {
        let list = files[group_name];
        let group = new THREE.Group();
        list.forEach((file, index) => {
        
          loader.load( file, gltf => {
              
            gltf.scene.traverse( function( object ) {
              if ( object.isMesh) {
                object.material.metalness = 1;
                object.name = group_name;
                if (!objs[group_name]) {
                  objs[group_name] = new Array();
                  if (list.length > 1) {
                    // 模型翻转，产生拼装的效果
                    object.scale.multiply(new THREE.Vector3(1, 1, -1));
                  }
                }
                objs[group_name].push(object);
                objs['all'].push(object);
                group.add( object ); 
              }
            });

            this.render();
          });
        });
        group.position.z = pos_z;
        pos_z += 20;
        scene.add(group);
      });
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize( window.innerWidth, window.innerHeight );
		},
    render() {
      renderer.render( scene, camera );
    },
    animate() {
        requestAnimationFrame(this.animate);
        controls.update();
    },
    initThree() {
      const three_dom = document.getElementById('three');
      let file = '/data/asm.gltf';
      let files = {
        'pj':['/data/pj.stp.gltf'],
        'pj7':['/data/pj7.stp.gltf'],
        'zc':['/data/zc.stp.gltf'],
        'top':['/data/3.stp.gltf', '/data/3.stp.gltf'],
        'zz-1':['/data/zz-1.stp.gltf'],
        'zz-2':['/data/zz-2.stp.gltf'],
        'zz-3':['/data/zz-3.stp.gltf'],
        'ty':['/data/ty.stp.gltf'],
      };

      this.initScene();
      this.initCamera();
      this.initRenderer(three_dom);
      this.loadGLTF(files, this.objs);
      this.initLight();
      this.initDragControl();

      groupObj = new THREE.Group();
      scene.add(groupObj);

      mouse = new THREE.Vector2( 1, 1 );
      raycaster = new THREE.Raycaster();

      // 轨道控制器，旋转、平移、缩放
      controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener( 'change', this.render ); // call this only in static scenes (i.e., if there is no animation loop)

      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      controls.dampingFactor = 0.05;
      controls.screenSpacePanning = false;
      controls.enableRotate = true; // 是否启用水平旋转
      controls.minDistance = 100;
			controls.maxDistance = 500;
      controls.maxPolarAngle = Math.PI / 2;
    
      // 坐标轴
      let axisHelper = new THREE.AxesHelper(200)
      scene.add(axisHelper)
      this.render();
      this.animate();
      // 盒子
      // const geometry = new THREE.BoxGeometry(50, 50, 50);
      // const material = new THREE.MeshBasicMaterial({ color: 'red' });
      // const cube = new THREE.Mesh(geometry, material);

      // objs.push(cube);
      // scene.add(cube);

      window.addEventListener( 'resize', this.onWindowResize );
      three_dom.addEventListener( 'mousedown', this.onClick );
    },
    onClick(event) {
        event.preventDefault();
				mouse.x = ( event.clientX / this.$refs.thr.clientWidth ) * 2 - 1;
				mouse.y = - ( event.clientY / this.$refs.thr.clientHeight ) * 2 + 1;

        // 通过摄像机和鼠标位置更新射线
        raycaster.setFromCamera( mouse, camera );
        // 计算物体和射线的焦点
				let intersection = raycaster.intersectObjects( this.objs['all'] );
        

				if ( intersection.length > 0 ) {
          selected_obj = intersection[0].object;
          
          const dragObjs = dragcontrols.getObjects();

          // 有属性配置
          if (infomation[selected_obj.name]) {
            console.log(event.clientY, event.clientX);
            this.$refs.infomation.style.top = event.clientY + 'px';
            this.$refs.infomation.style.left = event.clientX + 'px';
            this.$refs.infomation.style.display = 'block';
            this.updateInfo(infomation[selected_obj.name]);
          }
            
          // // 如果相同组的模型超过两个，那么移动的对象需要做特殊处理
          if (this.objs[selected_obj.name].length > 1) {
            // 先清空拖动对象
            dragObjs.length = 0;
            this.objs[selected_obj.name].forEach(o => {
              // 一个小组的对象添加到一个模型组
              groupObj.attach(o);
            });

            // 开启允许按组拖动对象
            dragcontrols.transformGroup = true;

            // 将模型组添加到拖动对象中去
            dragObjs.push(groupObj);

            selected_obj = this.objs[selected_obj.name];
            this.render();
          } else {
            dragcontrols.transformGroup = false;
            dragObjs.push(...this.objs['all']);
            this.render();
          }
				} else {
          this.$refs.infomation.style.display = 'none';
        }
    },
    change(evnt) {
      let color = new THREE.Color(evnt);
      if (selected_obj) {
        if (selected_obj.length > 1) {
          selected_obj.forEach(item => {
            item.material.color = color;
          });
        } else {
          selected_obj.material.color = color;
        }
        
        this.render();
      }
    },
    updateInfo(data) {
      console.log(data);
      this.info = data;
    },
    changeColor(c) {
      return { color: c };
    }
  }
}
</script>

<style scoped>
html {
  font-size: 10vw;
}
.wrap {
  width: 100%;
  height: 100%;
  position: absolute;
}
#three {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.wrap .data {
  position: absolute;
  top: 0;
  right: .25rem;  
  width: 80%;
  padding: 0rem;
  margin: .2rem;
  border: 1px solid rgb(198, 240, 82); 
}
.wrap .data p {
  display: flex;
  margin: 0rem;
  border: 1px solid rgb(198, 240, 82); 
}
.wrap .data span {
  width: 100%;
  padding: .4rem;
  text-align: center;
  display: inline-block;
  border: 1px solid rgb(198, 240, 82); 
}
.wrap .data .header {
  color:goldenrod;
}
.wrap .data .num {
  color:limegreen;
}
.wrap .infomation {
  position: absolute;
  width: 10rem;
  padding: .3rem;
  margin: .2rem;
  background: rgba(236, 238, 235, 0.18);
  border: 1px solid rgb(240, 82, 82); 
  border-radius: 16px;
  display: none;
}
.wrap .infomation p {
  width: 10rem;
}
.wrap .infomation .left {
  width: 40%;
  padding-right: .2rem;
  text-align: right;
  display: inline-block;
  color: rgb(18, 96, 241);
}
.wrap .infomation .right {
  text-align: left;
  display: inline-block;
  color: chartreuse;
}
</style>