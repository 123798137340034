<template>
  <span @click="edit()">
    <input class="edit" v-if="isEdit == true && num != 3" type="text" :value="newValue" @blur="save($event)" ref="inpu" />
    <select class="edit" v-if="isEdit == true && num == 3" type="text" :value="newValue" @blur="save($event)" ref="inpu">
      <option v-for="item in select" :key="item">{{item}}</option>
    </select>
    <b v-if="isEdit == false">{{newValue}}</b>
  </span>
</template>

<script>
export default {
  name: 'input-dom',
  props: {
    value: String,
    num: Number,
  },
  data: function() {
    return {
      isEdit: false,
      select: [
        'M1',
        'M2',
        'M3',
      ],
      newValue: localStorage.getItem('i_v_' + this.num) ? localStorage.getItem('i_v_' + this.num) : this.value
    };
  },
  methods: {
    edit() {
      this.isEdit = true;
      if (this.num != 3) {
        this.$nextTick(() => {
          this.$refs.inpu.focus();
        });
      }
    },
    save(e) {
      localStorage.setItem('i_v_' + this.num, this.$refs.inpu.value);
      this.newValue = this.$refs.inpu.value;
      this.isEdit = false;
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.edit {
  width: 100%;
  display: inline-block;
  border: 0;
  background-color:black;
  color: aliceblue;
}
</style>
