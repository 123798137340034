import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import {ColorPicker, ColorPanel} from 'one-colorpicker'


Vue.config.productionTip = false
Vue.use(ColorPanel)
Vue.use(ColorPicker)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
