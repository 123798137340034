<template>
  <div class="wrap">
    <div id="three" ref="thr">
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DragControls } from 'three/examples/jsm/controls/DragControls.js'

let scene, renderer, camera, controls;
export default {
  name: "vr",
  mounted() {
    this.initThree()
  },
  data: function() {
    return {
      objs: [],
      color: '',
    };
  },
  methods: {
    // 初始化一个场景
    initScene() {
      scene = new THREE.Scene();
      scene.background = new THREE.Color( 0x000000 );
    },
    // 初始化一个摄像机
    initCamera() {
      camera = new THREE.PerspectiveCamera( 90, this.$refs.thr.clientWidth / this.$refs.thr.clientHeight, 0.1, 100 );
      camera.position.set(0, 0, 0.01);
    },
    // 初始化一个光源
    initLight() {
      let directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 100, 300);
      let dhelper = new THREE.DirectionalLightHelper(directionalLight, 5, 'red'); 
      scene.add(directionalLight);
      scene.add(dhelper);
    },
    // 初始化一个渲染器
    initRenderer(dom) {
      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( this.$refs.thr.clientWidth, this.$refs.thr.clientHeight );
      dom.appendChild(renderer.domElement);
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize( window.innerWidth, window.innerHeight );
		},
    render() {
      renderer.render( scene, camera );
    },
    animate() {
        requestAnimationFrame(this.animate);
        controls.update();
    },
    initThree() {
      const three_dom = document.getElementById('three');


      this.initScene();
      this.initCamera();
      this.initRenderer(three_dom);


      // 轨道控制器，旋转、平移、缩放
      controls = new OrbitControls(camera, renderer.domElement);
      controls.addEventListener( 'change', this.render );
      controls.enableZoom = false; //不给缩放

      var texture = new THREE.TextureLoader().load('/data/vr2.jpg', () => {
          this.render();
      });
      var sphereMaterial = new THREE.MeshBasicMaterial({map: texture});

      let sphereGeometry = new THREE.SphereGeometry(/*半径*/2, /*垂直节点数量*/50, /*水平节点数量*/50);//节点数量越大，需要计算的三角形就越多，影响性能
      sphereGeometry.scale(1, 1, -1);
      let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    //   sphere.material.wireframe  = true;//用线框模式大家可以看得清楚是个球体而不是圆形
      scene.add(sphere);
    
      this.render();
      this.animate();
    
      window.addEventListener( 'resize', this.onWindowResize );
    }
  }
}
</script>

<style scoped>
html {
  font-size: 10vw;
}
.wrap {
  width: 100%;
  height: 100%;
  position: absolute;
}
#three {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
</style>