import Vue from 'vue'
import Router from 'vue-router'
// 导入页面组件
import Three from './components/Three.vue'
import Vr from './components/Vr.vue'

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
           // 配置页面组件路由,名称,并注册
            path: '/',
            name: 'three',
            component: Three
        },
        {
            // 配置页面组件路由,名称,并注册
             path: '/vr',
             name: 'vr',
             component: Vr
         }
    ]
});